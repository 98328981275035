
					@import './src/sass/variables';
					@import './src/sass/mixins';
				































































.dropzone::v-deep {
	margin-bottom: $default_padding;
}

.current-file {
	display: flex;
	flex-flow: row nowrap;

	.audio-file {
		margin-right: $default_padding;
	}

	audio {
		outline: none;

		&::-webkit-media-controls-panel {
			background-color: $color__white;
		}
	}
}

.transcript {
	flex: 1;
}

h2 {
	margin: 0 0 1rem;
	font-size: 1.25rem;
}
